<div class="m-t-25">
  <div class="card">
    <div class="row p-2">
      <div class="col-md-2">
        <ng-select [items]="clusters" [multiple]="false" [closeOnSelect]="true" bindLabel="nom" bindValue="id" placeholder="Clusters" [(ngModel)]="filters.clusterId" (change)="changeSearch()" />
      </div>
      <div class="col-md-2">
        <ng-select
          [items]="statutHotes"
          [multiple]="false"
          [closeOnSelect]="true"
          bindLabel="libelle"
          bindValue="code"
          placeholder="{{ 'Statut' | translate }}"
          [(ngModel)]="filters.status"
          (change)="changeSearch()"
        />
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <input
            #searchInput
            id="search"
            type="text"
            placeholder="{{ 'Organization' | translate }} - {{ 'Site' | translate }} - IP"
            class="form-control form-control-sm"
            [(ngModel)]="filters.filter"
            (change)="changeSearch()"
            (keyup)="changeSearch()"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <ng-select
            [items]="hostModeService.modes"
            [multiple]="false"
            [closeOnSelect]="true"
            bindLabel="libelle"
            bindValue="value"
            placeholder="{{ 'Mode' | translate }}"
            [(ngModel)]="filters.mode"
            (change)="changeSearch()"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <button *ngIf="filters.clusterId" (click)="confirmProvCluster()" class="ml-2 btn btn-sm btn-danger pull-right">PROV CLUSTER</button>
          <button (click)="confirmCron12H()" class="btn btn-sm btn-primary pull-right">CRON 12H</button>
        </div>
      </div>
    </div>
    <ngx-datatable
      [messages]="{ emptyMessage: translate.instant('NoData') }"
      #myTable
      class="data-table table-responsive task-list-table"
      [rows]="sites"
      [columnMode]="'flex'"
      [headerHeight]="50"
      [footerHeight]="50"
      [limit]="10"
      [rowHeight]="45"
    >
      <ngx-datatable-column [flexGrow]="2" name="Statut">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Statut' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="label {{ 'siteStatus-' + row.status }}" [style.background-color]="row.statutHoteCouleur">{{ 'SiteStatus.' + row.status | translate }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="3" name="Owner">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Organization' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div *ngIf="!row.editOwner">
            <a
              >{{ row.owner.companyName }} &nbsp;
              <app-badge-organization [code]="row.owner.code"></app-badge-organization>
            </a>
            <i (click)="row.editOwner = !row.editOwner" class="pointer icofont icofont-edit-alt" style="color: #4099ff; font-size: 20px; margin-left: 10px" title="{{ 'Edit' | translate }}"></i>
          </div>
          <div *ngIf="row.editOwner" class="flex-align-items">
            <ng-select
              class="user-select"
              notFoundText="{{ 'Min3Chars' | translate }}"
              appendTo="body"
              (search)="loadOrganizations($event.term)"
              [items]="organizations"
              [clearable]="false"
              [multiple]="false"
              [closeOnSelect]="true"
              bindLabel="companyNameAndCode"
              bindValue="id"
              [(ngModel)]="row.organizationId"
            >
            </ng-select>
            <i
              (click)="row.editOwner = !row.editOwner; updateOrganizationOwner(row.name, row.organizationId)"
              class="pointer icofont icofont-check"
              style="font-size: 25px; color: rgb(64, 153, 255)"
              title="{{ 'Validate' | translate }}"
            ></i>
            <i (click)="row.editOwner = !row.editOwner" class="pointer icofont icofont-close" style="color: rgb(231, 76, 60); font-size: 20px" title="{{ 'Cancel' | translate }}"></i>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="3" name="Site">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Site' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a target="_blank" href="{{ 'http://' + row.name }}">{{ row.name }}</a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="2" name="IP Site">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'IpSite' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a>{{ row.destHost }}</a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="2" name="cluster">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Cluster' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a>{{ row.cluster.name }}</a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="2" name="creation" cellClass="date" [minWidth]="155" [maxWidth]="155">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader">{{ 'Creation' | translate }}</p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.createdAt | dateFormat: 'iso' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="2" name="Mode" [minWidth]="130" [maxWidth]="130">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Mode'"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="form-group">
            <select [ngModel]="row.mode" (change)="toggleMode(row.id, row.mode, $event)" class="form-control" style="position: relative; top: 8px">
              <option *ngFor="let m of hostModeService.modes" [value]="m.value">{{ m.value }}</option>
            </select>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="2" name="Managed" [minWidth]="90" [maxWidth]="90">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Managed'"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="form-group">
            <input [(ngModel)]="row.managed" [checked]="row.managed" (change)="updateSite(row)" type="checkbox" style="width: 20px; height: 20px; position: relative; top: 10px" />
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="2" name="action" [minWidth]="80" [maxWidth]="80">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Action'"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div (click)="refreshStatus(row)" class="label label-primary label-sm icon icon-reload" title="{{ 'Refresh' | translate }}"></div>
          <div (click)="forceProvSite(row)" class="label label-danger label-sm icon icon-export" title="{{ 'Provision' | translate }}"></div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template>
          <div>Total : {{ totalElements }}</div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="totalElements"
            (change)="changePage($event)"
          />
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>
