import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Global } from '../../global';
import { HostModeService } from '../../shared/hostMode/hostMode.service';
import _ from 'lodash';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from 'app/services/auth.service';
import { copyObject } from 'app/shared/utils/data-utils';

@Component({
  selector: 'app-geoblock',
  templateUrl: './geoblock.component.html',
  styleUrls: ['./geoblock.component.scss', '../../../assets/icon/icofont/css/icofont.scss', '../add-site.component.scss'],
})
export class GeoblockComponent implements OnInit {
  @Input() hote: any = {};

  @ViewChild('country') country: ElementRef;
  @ViewChild('blockCountry') blockCountry: ElementRef;

  countries: any = [];
  countriesTmp: any = [];
  blockedCountries: any = [];
  blockedCountriesTmp: any = [];
  provisionningInProgress: boolean = false;
  lang: string;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
    private auth: AuthService,
    public hostModeService: HostModeService,
  ) {}

  ngOnInit(): void {
    this.lang = this.auth.getCurrentLanguage();
    if (this.hote.id) {
      this.getCountries(this.lang);
      this.getBlacklistedCountries();
    }
  }

  ngAfterViewInit() {
    fromEvent(this.country.nativeElement, 'keyup')
      .pipe(debounceTime(500))
      .subscribe((evt: any) => {
        this.countries = this.countries.map((p: any) => {
          const str = evt.target.value.toLowerCase();
          const name = p.countryNameFr.toLowerCase();
          const code = p.countryCode.toLowerCase();
          p.hide = !(name.indexOf(str) > -1 || code.indexOf(str) > -1);
          return p;
        });
      });

    fromEvent(this.blockCountry.nativeElement, 'keyup')
      .pipe(debounceTime(500))
      .subscribe((evt: any) => {
        this.blockedCountries = this.blockedCountries.map((p: any) => {
          const str = evt.target.value.toLowerCase();
          const name = p.countryNameFr.toLowerCase();
          const code = p.countryCode.toLowerCase();
          p.hide = !(name.indexOf(str) > -1 || code.indexOf(str) > -1);
          return p;
        });
      });
  }

  getBlacklistedCountries() {
    if (!this.hote.id) {
      return;
    }
    const req = {
      data: {
        siteId: this.hote.id,
      },
    };
    this.http.post(Global.baseUrl + 'blacklistedCountry/get', req).subscribe((res: any) => {
      if (!res.hasError) {
        this.blockedCountries = res.items || [];
        this.countries = _.filter(this.countriesTmp, (p: any) => {
          const fd = this.blockedCountries.find((e: any) => e.countryCode === p.countryCode);
          return !fd;
        });
      }
      this.blockedCountriesTmp = this.blockedCountries;
    });
  }

  getCountries(lang) {
    this.http.get('/assets/data/countries_' + lang + '.json').subscribe((data: any) => {
      this.countries = data.map((p: any) => {
        return {
          countryNameEn: p.name,
          countryNameFr: p.name,
          countryCode: p.alpha2,
        };
      });
      this.countriesTmp = this.countries;
    });
  }

  blockAllCountries() {
    this.blockedCountries = copyObject(this.countriesTmp);
    this.countries = [];
    this.blockedCountries.filter((c) => this.blockedCountriesTmp.findIndex((tmp) => c.countryCode == tmp.countryCode) === -1);
  }

  enableAllCountries() {
    this.countries = copyObject(this.countriesTmp);
    this.blockedCountries = [];
  }

  blockAvailableCountry(p: any, i: number) {
    this.countries.splice(i, 1);
    this.blockedCountries.unshift({ ...p });
  }

  enableBlockCountry(p: any, i: number) {
    this.blockedCountries.splice(i, 1);
    this.countries.unshift({ ...p });
  }

  createBlacklistedCountries(p) {
    if (!this.hostModeService.isAdvanced(this.hote.mode)) {
      return this.hostModeService.promptSuscribeToMode('SuscribeToAdvanced');
    }

    const req = {
      data: {
        siteId: p.id,
        datasBlacklistedCountry: this.blockedCountries,
      },
    };

    this.provisionningInProgress = true;

    this.http.post(Global.baseUrl + 'blacklistedCountry/create', req).subscribe((res: any) => {
      if (res.hasError === false) {
        this.toastr.success(this.translate.instant('OperationSuccess'));
      } else {
        this.toastr.error(this.translate.instant('OperationFailed'));
      }
      this.provisionningInProgress = false;
    });
  }
}
