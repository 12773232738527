<div class="card with-tabs p-3">
  <div class="row">
    <div class="col-12">
      <h5 class="flex-align-items space-between">
        <div>
          {{ 'ExpertMode' | translate }}
          <label class="badge badge-sm bg-dark"
            ><a
              style="color: inherit"
              target="_blank"
              [href]="
                lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK + '/mode-expert' : EXTERNAL_HELP_ENGLISH_LINK + '/expert-mode'
              "
              >?</a
            ></label
          >
        </div>
        <div>
          <button
            type="button"
            (click)="toggleEditMode()"
            class="btn btn-primary btn-sm pull-right"
            [hidden]="editMode"
            [innerHTML]="'Modifier' | translate"
          ></button>
          <button
            type="button"
            (click)="toggleEditMode()"
            [hidden]="!editMode"
            class="btn btn-sm btn-primary btn-sm pull-right"
            [innerHTML]="'Enregistrer' | translate"
            [disabled]="errors.length || isLoading"
          ></button>
          <button
            type="button"
            (click)="cancelUpdate()"
            [hidden]="!editMode"
            class="btn btn-danger btn-sm pull-right mr-2"
            [innerHTML]="'Annuler' | translate"
          ></button>
        </div>
      </h5>
      <p class="mb-0">{{ 'ExpertModeDescription' | translate }}</p>
      <hr style="margin-top: 0.8rem; margin-bottom: 0.8rem" />
      <div *ngIf="hote.mode != hostModeService.expert" class="bg-blue">{{ 'SubscribeToExpert' | translate }}</div>
      <div *ngIf="brainParams.length">
        <div class="drive-search">
          <input
            [disabled]="hote.mode != hostModeService.expert"
            [(ngModel)]="driveSearch"
            (keydown)="openAllGroups()"
            id="drive-search"
            class="form-control"
            [placeholder]="'SearchADrive' | translate"
          />
          <i class="icon-search"></i>
        </div>
        <div class="expert-container custom-scrollbar w-100">
          <div
            *ngFor="let g of brainParams"
            class="group-container transition"
            [ngClass]="{ disabled: !editMode, 'no-expert-mode': hote.mode != hostModeService.expert }"
          >
            <div class="flex-align-items space-between pointer w-100" (click)="g.open = !g.open">
              <h5>
                <span *ngIf="g.hasModifiedParam" class="blue-bullet-point"></span>
                {{ g.groupName }}
                <span *ngIf="g.countModifiedDrives > 0" class="count-modified">({{ g.countModifiedDrives }})</span>
              </h5>
              <i *ngIf="!g.open" class="icofont icofont-simple-down collapse-arrow"></i>
              <i *ngIf="g.open" class="icofont icofont-simple-up collapse-arrow"></i>
            </div>
            <ng-container *ngIf="g.open">
              <div
                *ngFor="let d of g.drives | callback: driveFilter"
                class="drive-container"
                [ngClass]="{ modified: !!d.hasModifiedParam }"
              >
                <div class="drive-label">
                  <span *ngIf="d.hasModifiedParam" class="blue-bullet-point"></span>
                  {{ d.label }} - <span class="little">{{ d.desc }} </span>&nbsp;
                  <label
                    *ngIf="labelContains(d.label, 'SRL -')"
                    class="badge badge-sm bg-dark"
                    ngbTooltip="{{ ('LearnMoreAbout' | translate) + ' Smart Rate Limiting (SRL)' }}"
                    ><a
                      style="color: inherit; font-size: 10.5px"
                      target="_blank"
                      [href]="
                        lang == 'fr'
                          ? EXTERNAL_HELP_FRENCH_LINK + '/smart-rate-limiting'
                          : EXTERNAL_HELP_ENGLISH_LINK + '/smart-rate-limiting-9de42b71'
                      "
                      >?</a
                    ></label
                  >
                  <label
                    *ngIf="labelContains(d.label, 'Boost -')"
                    class="badge badge-sm bg-dark"
                    ngbTooltip="{{ 'DriveBoostLabel' | translate }}"
                    >?</label
                  >
                </div>
                <div class="flex-align-items wrap drive-parameters-container">
                  <div *ngFor="let p of d.params">
                    <div *ngIf="p.type == 'toggleButton'" class="switch-container">
                      <label class="input-label active">{{ p.label }}</label>
                      <span
                        style="margin-right: 5px"
                        [ngStyle]="{
                          color:
                            brainOverrides.get(p.id).overrided &&
                            (brainOverrides.get(p.id).value == 0 || brainOverrides.get(p.id).value == false)
                              ? '#4099ff'
                              : ''
                        }"
                        >{{ 'Désactivé' | translate }}</span
                      >
                      <label class="switch">
                        <input
                          [(ngModel)]="brainOverrides.get(p.id).value"
                          type="checkbox"
                          [ngStyle]="{ cursor: editMode ? 'pointer' : 'not-allowed' }"
                          [disabled]="!editMode"
                        />
                        <span
                          class="slider round"
                          [ngStyle]="{ opacity: editMode ? '1' : '0.5', cursor: editMode ? 'pointer' : 'not-allowed' }"
                        ></span>
                      </label>
                      <span
                        [ngStyle]="{
                          color:
                            brainOverrides.get(p.id).overrided &&
                            (brainOverrides.get(p.id).value > 0 || brainOverrides.get(p.id).value == true)
                              ? '#4099ff'
                              : ''
                        }"
                      >
                        {{ 'Activé' | translate }}</span
                      >
                    </div>
                    <div *ngIf="p.type == 'input'" class="flex-align-items">
                      <label class="input-label">{{ p.label }}</label>
                      <input
                        (keyup)="onInputChange($event.target, brainOverrides.get(p.id), p.min, p.max)"
                        [(ngModel)]="brainOverrides.get(p.id).value"
                        [disabled]="!editMode"
                        [placeholder]="p.defaultValue"
                        [ngClass]="{
                          overrided: brainOverrides.get(p.id).value && !brainOverrides.get(p.id).modified,
                          modified: brainOverrides.get(p.id).value && brainOverrides.get(p.id).modified
                        }"
                        class="form-control form-control-sm value"
                      />
                      <small class="min-max">Min : {{ p.min }} - Max : {{ p.max }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <hr style="margin-bottom: 0.8rem" />
        <button
          (click)="editMode && restoreDefault()"
          class="restore-default btn btn-sm btn-outline-primary btn-md pull-right"
          [disabled]="!editMode || isLoading"
        >
          {{ 'RestoreDefault' | translate }}
        </button>
      </div>
      <div *ngIf="!brainParams.length" class="theme-loader">
        <div class="loader-track">
          <div class="loader-bar"></div>
        </div>
      </div>
    </div>
  </div>
</div>
