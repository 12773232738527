import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { Global } from '../../global';
import { copyObject, subnet } from '../../shared/utils/data-utils';
import { AuthService } from 'app/services/auth.service';
import { EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK } from 'app/app-routing.module';
import { validateIp } from '../../shared/validators/validators';

@Component({
  selector: 'app-exceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: [
    './exceptions.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss',
    '../add-site.component.scss',
  ],
})
export class ExceptionsComponent implements OnInit {
  lang: string;

  @Input() hote: any = {};

  urlExceptions: any = [];
  whitelistedIps: any = [];

  tmpUrlException: any = {};
  tmpWhitelistedIp: any = {};

  @ViewChild('urlExceptionElement') urlExceptionElement: ElementRef;
  @ViewChild('whitelistedIpElement') whitelistedIpElement: ElementRef;

  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private auth: AuthService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.lang = this.auth.getCurrentLanguage();

    if (this.hote.id) {
      this.getUrlException();
      this.getWhitelistedIp();
    }
  }

  getUrlException() {
    this.urlExceptions = [];
    this.http
      .post(Global.baseUrl + 'urlException/get', {
        data: {
          hoteId: this.hote.id,
        },
      })
      .subscribe(
        (res: any) => {
          this.urlExceptions = (res.items || []).reverse();
        },
        (error: any) => {
          console.log('Error : ', error);
        },
      );
  }

  getWhitelistedIp() {
    this.whitelistedIps = [];
    this.http
      .post(Global.baseUrl + 'whitelistedIp/get', {
        data: {
          hoteId: this.hote.id,
        },
      })
      .subscribe(
        (res: any) => {
          this.whitelistedIps = (res.items || []).reverse();
        },
        (error: any) => {
          console.log('Error : ', error);
        },
      );
  }

  addUrlException() {
    this.urlExceptions.push({
      new: true, // front only
      uri: '/',
      comments: '',
    });
    setTimeout(() => this.urlExceptionElement.nativeElement.focus(), 1);
  }

  isCreatingUrlException() {
    return this.urlExceptions.some((rule) => rule.new);
  }

  createUrlException(urlException: any) {
    const uri = urlException.uri.trim();
    if (this.urlExceptions.find((u) => u.uri == uri && !u.new)) {
      return this.toastr.error(this.translate.instant('AlreadyExists'));
    }

    const request = {
      datas: [
        {
          hoteId: this.hote.id,
          uri,
          comments: urlException.comments,
        },
      ],
    };

    this.http.post(Global.baseUrl + 'urlException/create', request).subscribe((res: any) => {
      if (!res.hasError) {
        this.urlExceptions.splice(
          this.urlExceptions.findIndex((u) => u.new),
          1,
        );
        this.urlExceptions.push(res.items[0]);
        this.toastr.success(this.translate.instant('EnregistrementReussi'));
        this.addUrlException();
      } else {
        this.toastr.error(this.translate.instant('EnregistrementEchoue'));
      }
    });
  }

  deleteUrlException(urlException: any) {
    if (urlException.editing) {
      urlException.editing = false;
      urlException.uri = this.tmpUrlException.uri;
      urlException.comments = this.tmpUrlException.comments;
      return;
    }

    if (urlException.id) {
      const request = {
        datas: [
          {
            hoteId: this.hote.id,
            uri: urlException.uri,
          },
        ],
      };
      this.http.post(Global.baseUrl + 'urlException/delete', request).subscribe((res: any) => {
        if (!res.hasError) {
          this.getUrlException();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      });
    } else {
      this.urlExceptions.splice(
        this.urlExceptions.findIndex((r) => r.new),
        1,
      );
    }
  }

  startUrlExceptionEditing(urlException) {
    this.tmpUrlException = copyObject(urlException); // deep copy in order to restore if cancelling editing
    urlException.editing = true;
    setTimeout(() => this.urlExceptionElement.nativeElement.focus(), 1);
  }

  validateUrlExceptionEditing(urlException) {
    if (urlException.uri) {
      urlException.editing = false;

      const request = {
        datas: [
          {
            hoteId: this.hote.id,
            uri: urlException.uri,
            id: urlException.id,
            comments: urlException.comments,
          },
        ],
      };

      this.http.post(Global.baseUrl + 'urlException/update', request).subscribe((res: any) => {
        if (!res.hasError) {
          this.getUrlException();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      });
    }
  }

  isEditingUrlException() {
    return this.urlExceptions.some((urlException) => urlException.editing);
  }

  handleEnterUrlException(event, urlException) {
    if (event.keyCode === 13 && !event.shiftKey) {
      urlException.editing ? this.validateUrlExceptionEditing(urlException) : this.createUrlException(urlException);
      event.preventDefault();
    } else if (event.keyCode === 27) {
      this.deleteUrlException(urlException);
    }
  }

  addWhitelistedIp() {
    this.whitelistedIps.push({
      new: true, // front only
      ip: '',
      comments: '',
    });
    setTimeout(() => this.whitelistedIpElement.nativeElement.focus(), 1);
  }

  createWhitelistedIp(whitelistedIp) {
    let ip = whitelistedIp.ip.trim();

    if (!validateIp(ip)) {
      this.toastr.error(this.translate.instant('WrongIp'));
      return;
    }

    if (this.whitelistedIps.find((w) => w.ip == ip && !w.new)) {
      return this.toastr.error(this.translate.instant('AlreadyExists'));
    }

    const request = {
      datas: [
        {
          hoteId: this.hote.id,
          ip: subnet(ip),
          comments: whitelistedIp.comments,
        },
      ],
    };

    this.http.post(Global.baseUrl + 'whitelistedIp/create', request).subscribe((res: any) => {
      if (!res.hasError) {
        this.whitelistedIps.splice(
          this.whitelistedIps.findIndex((w) => w.new),
          1,
        );
        this.whitelistedIps.push(res.items[0]);
        this.toastr.success(this.translate.instant('IPWellAddedtoWhitelist'));
        this.addWhitelistedIp();
      } else {
        this.toastr.error(this.translate.instant('EnregistrementEchoue'));
      }
    });
  }

  deleteWhitelistedIp(whitelistedIp) {
    if (whitelistedIp.editing) {
      whitelistedIp.editing = false;
      whitelistedIp.ip = this.tmpWhitelistedIp.ip;
      whitelistedIp.comments = this.tmpWhitelistedIp.comments;
      return;
    }

    if (whitelistedIp.id) {
      const request = {
        datas: [
          {
            hoteId: this.hote.id,
            ip: whitelistedIp.ip,
          },
        ],
      };
      this.http.post(Global.baseUrl + 'whitelistedIp/delete', request).subscribe((res: any) => {
        if (!res.hasError) {
          this.getWhitelistedIp();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      });
    } else {
      this.whitelistedIps.splice(
        this.whitelistedIps.findIndex((w) => w.new),
        1,
      );
    }
  }

  startWhitelistedIpEditing(whitelistedIp) {
    this.tmpWhitelistedIp = copyObject(whitelistedIp); // deep copy in order to restore if cancelling editing
    whitelistedIp.editing = true;
    setTimeout(() => this.whitelistedIpElement.nativeElement.focus(), 1);
  }

  validateWhitelistedIpEditing(whitelistedIp) {
    if (whitelistedIp.ip) {
      whitelistedIp.editing = false;

      const request = {
        datas: [
          {
            hoteId: this.hote.id,
            ip: whitelistedIp.ip,
            id: whitelistedIp.id,
            comments: whitelistedIp.comments,
          },
        ],
      };

      this.http.post(Global.baseUrl + 'whitelistedIp/update', request).subscribe((res: any) => {
        if (!res.hasError) {
          this.getWhitelistedIp();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      });
    }
  }

  isEditingWhitelistedIp() {
    return this.whitelistedIps.some((whitelistedIp) => whitelistedIp.editing);
  }

  isCreatingWhitelistedIp() {
    return this.whitelistedIps.some((rule) => rule.new);
  }

  handleEnterWhitelistedIp(event, whitelistedIp) {
    if (event.keyCode === 13 && !event.shiftKey) {
      whitelistedIp.editing
        ? this.validateWhitelistedIpEditing(whitelistedIp)
        : this.createWhitelistedIp(whitelistedIp);
      event.preventDefault();
    } else if (event.keyCode === 27) {
      this.deleteWhitelistedIp(whitelistedIp);
    }
  }
}
