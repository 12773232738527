<app-site-selector
  #siteSelector
  [ctx]="ctx"
  (change)="onSiteSelectionChanged()"
  [options]="{
    showClusters: true,
    showTags: ctx != ADMIN_CONTEXT,
    showSites: true,
    showDatePicker: true,
    canAutoRefresh: true
  }"
  [isLoading]="isLoading"
  (refresh)="refresh()"
  (onPeriodEmitted)="onPeriodEmitted($event)"
  [datePickerSlug]="'logs'"
>
  <button
    class="btn btn-primary btn-download"
    title="{{ 'Download' | translate }}"
    (click)="downloadLogs()"
    [disabled]="isLoading"
  >
    <i class="icofont icofont-download"></i>
  </button>
  <a id="downloadAnchorElem" [hidden]="true"></a>
</app-site-selector>

<app-my-logs-filters
  #filtersComponent
  [isLoading]="isLoading"
  (filtersEmitter)="onFiltersChange($event)"
></app-my-logs-filters>

<app-my-logs-graph
  #graphsComponent
  [isLoading]="isLoading"
  (refresh)="refresh()"
  (getLogs)="loadData()"
  (onPeriodEmitted)="onPeriodEmittedFromGraph($event)"
  (track)="createFiltersFromEvent($event)"
  [filters]="filters"
  [ctx]="ctx"
/>

<div class="logs-container card mt-4">
  <ngx-datatable
    *ngIf="!isLoading; else loader"
    [messages]="{ emptyMessage: translate.instant('NoData') }"
    #logsTable
    class="material data-table table-responsive my-logs-table"
    [rows]="logs"
    [limit]="size"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="40"
    [scrollbarH]="logsTableMustScroll"
    [columnMode]="'force'"
  >
    <ngx-datatable-row-detail [rowHeight]="'auto'">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div style="padding: 20px">
          <json-viewer
            [json]="row.requestDetails"
            [expanded]="true"
            [highlightedFields]="row?.drivesList?.highlight"
          ></json-viewer>
          <div *ngIf="row?.drivesList?.drives?.length">
            <hr />
            <div class="bg-drives" style="text-align: left">
              <h6>{{ (row?.drivesList?.drives.length > 1 ? 'AnomaliesDetected' : 'AnomalyDetected') | translate }}</h6>
              <div style="line-height: 2">
                <div *ngFor="let d of row.drivesList.drives">
                  {{ d.category != d.label ? d.category + ' - ' : '' }}{{ d.label }}
                  <label
                    *ngIf="d.description"
                    class="badge badge-sm bg-dark"
                    placement="bottom"
                    container="body"
                    [ngbTooltip]="d.description"
                    >?</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>

    <ngx-datatable-column [maxWidth]="80" cellClass="cell">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
        <span (click)="toggleExpandRow(row)" class="ogo-link" style="font-size: 12px">
          <a
            href="javascript:void(0)"
            class="ogo-link icon"
            [class.icon-angle-down]="expanded"
            [class.icon-angle-right]="!expanded"
            style="font-size: 11px; font-weight: bold"
            title="{{ 'Details' | translate }}"
          >
          </a>
          {{ 'Details' | translate }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="1" cellClass="cell">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Date' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="{{ row.date | dateFormat }}">{{ row.date | dateFormat }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="2" cellClass="cell-no-pad">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Site' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #siteContent let-row="row">
          <span title="{{ row.site }}">
            <small *ngIf="row.dryRun" class="label pull-left mr-2" style="background-color: #333">
              {{ 'ModeAudit' | translate }}
            </small>
            <span>{{ row.site }}</span>
          </span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: 'site',
              cellValue: row.site,
              template: trackTemplate,
              customContent: siteContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="3" cellClass="cell-no-pad">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Path' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #pathContent let-row="row">
          <small
            *ngIf="row.rewriteOriginalPath"
            class="label pull-left mr-2"
            title=""
            placement="bottom"
            ngbTooltip="{{ 'RemplacedURL' | translate }}"
            style="background-color: #333; cursor: default"
            >R</small
          >
          <span title="{{ row.path }}">{{ row.path }}</span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.PATH,
              cellValue: row.path,
              template: trackTemplate,
              customContent: pathContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column cellClass="cell-no-pad">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'IP' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #ipContent let-row="row">
          <span class="flex-align-items">
            <div class="flex-ip-flag w-100" title="{{ row.clientIP + ' (' + row.countryCode + ')' }}">
              <div
                (click)="createFilter(LogFiltersType.IN, FiltersEnum.COUNTRY, row.countryCode)"
                *ngIf="row.countryCode"
                [attr.title]="row.countryName"
                class="pointer fi fi-{{ row.countryCode?.toLowerCase() }}"
              ></div>
              &nbsp;
              <div class="text-ellipsis ip-address" style="position: relative; top: -1px">{{ row.clientIP }}</div>
            </div>
          </span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.IP,
              cellValue: row.clientIP,
              template: trackTemplate,
              customContent: ipContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngIf="![RESPONSE_TIME, BANDWIDTH].includes(graphType)" cellClass="cell">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader">
          {{ 'Credibility' | translate }}
          <label
            class="badge badge-sm bg-dark"
            container="body"
            placement="bottom"
            ngbTooltip="{{ 'CredibilityTooltip' | translate }}"
            >?</label
          >
        </p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div
          #progressBar
          class="progress-bar-container progress-bar-gradient"
          [title]="
            row.rt &&
            row.credibility != -1 &&
            row.cause != CauseEnum.WHITELISTED_IP &&
            row.cause != CauseEnum.URL_EXCEPTION &&
            row.action != ActionEnum.REDIRECT
              ? Math.trunc(row.credibility / 1000) + '%'
              : ''
          "
        >
          <div
            [ngStyle]="{ width: 100 - (row.credibility || 100) / 1000 + '%' }"
            style="min-width: 0.01em"
            class="progress-bar-cache"
          >
            <div
              *ngIf="
                row.credibility == -1 && row.cause != CauseEnum.GEO_BLOCKED && row.cause != CauseEnum.WHITELISTED_IP
              "
              class="progress-badge ban"
            >
              {{ 'Banned' | translate }}
            </div>
            <div *ngIf="row.credibility == -1 && row.cause == CauseEnum.GEO_BLOCKED" class="progress-badge ban">
              {{ CauseEnum.GEO_BLOCKED | translate }}
            </div>
            <div
              *ngIf="row.credibility == -1 && row.cause == CauseEnum.WHITELISTED_IP"
              class="progress-badge exception"
            >
              {{ CauseEnum.WHITELISTED_IP | translate }}
            </div>
            <div *ngIf="row.cause == CauseEnum.URL_EXCEPTION" class="progress-badge exception">
              {{ CauseEnum.URL_EXCEPTION | translate }}
            </div>
            <div *ngIf="row.cause == CauseEnum.PASSTHROUGH_RULE" class="progress-badge exception">
              {{ CauseEnum.PASSTHROUGH_RULE | translate }}
            </div>
            <div *ngIf="row.action == ActionEnum.REDIRECT" class="progress-badge exception">
              {{ ActionEnum.REDIRECT | translate }}
            </div>
            <div *ngIf="row.cause == CauseEnum.BLOCKED_BY_RULE" class="progress-badge exception">
              {{ CauseEnum.BLOCKED_BY_RULE | translate }}
            </div>
          </div>
          <div
            [ngStyle]="{ top: '-10px', background: row.credibility / 1000 < row.rt ? '#ff5370' : '' }"
            class="progress-bar-progressbar progress-{{ row.credibility / 1000 }}"
          ></div>
          <div
            *ngIf="
              row.rt &&
              row.credibility != -1 &&
              row.cause != CauseEnum.WHITELISTED_IP &&
              row.cause != CauseEnum.URL_EXCEPTION &&
              row.action != ActionEnum.REDIRECT
            "
            class="progress-bar-threshold"
            [ngStyle]="{ width: (progressBar.offsetWidth / 100) * row.rt + 'px' }"
          ></div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="![RESPONSE_TIME, BANDWIDTH].includes(graphsComponent.graphType)"
      cellClass="cell-no-pad"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Action' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #actionContent let-row="row">
          <span title="{{ row.action | translate }}" class="action-{{ row.action }}">
            {{ row.action | translate }}
            {{ row.dryRun && row.action == ActionEnum.BLOCKED ? '(N/A)' : '' }}
          </span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.ACTION,
              cellValue: row.action,
              template: trackTemplate,
              customContent: actionContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="![RESPONSE_TIME, BANDWIDTH].includes(graphsComponent.graphType)"
      cellClass="cell-no-pad"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'MainCause' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #causeContent let-row="row">
          <span title="{{ row.cause | translate }}">
            {{ row.mainAttackLabel || (row.cause | translate) }}
          </span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.CAUSE,
              cellValue: row.cause,
              template: trackTemplate,
              customContent: causeContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="[RESPONSE_TIME, BANDWIDTH].includes(graphsComponent.graphType)"
      cellClass="cell-no-pad"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Method' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #methodContent let-row="row">
          <span title="{{ row.method }}">{{ row.method }}</span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.METHOD,
              cellValue: row.method,
              template: trackTemplate,
              customContent: methodContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column cellClass="cell-no-pad">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'StatusCode' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #statusCodeContent let-row="row">
          <span title="{{ row.code }}">{{ row.code }}</span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.STATUS_CODE,
              cellValue: row.code,
              template: trackTemplate,
              customContent: statusCodeContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngIf="graphsComponent.graphType == RESPONSE_TIME" cellClass="cell-no-pad">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader">{{ 'ResponseTime' | translate }}</p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #responseTimeContent let-row="row">
          <span title="{{ row.responseTimeMs }}">{{ row.responseTimeMs | duration }}</span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.RESPONSE_TIME_GTE,
              cellValue: Math.round(row.responseTimeMs),
              template: trackTemplate,
              customContent: responseTimeContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="80"
      [maxWidth]="80"
      *ngIf="![RESPONSE_TIME, BANDWIDTH].includes(graphsComponent.graphType)"
      cellClass="cell"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Rule' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div placement="left" container="body">
          {{ row.rulePriority ? '#' + row.rulePriority : '-' }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" *ngIf="graphsComponent.graphType == BANDWIDTH" cellClass="cell-no-pad">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'ContentLengthHeader' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-template #contentLengthContent let-row="row">
          <span title="{{ row.contentLength }}">{{ row.contentLength | numberFormat }}</span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.CONTENT_LENGTH_GTE,
              cellValue: row.contentLength,
              template: trackTemplate,
              customContent: contentLengthContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngIf="graphsComponent.graphType == BANDWIDTH" cellClass="cell-no-pad">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'CacheStatus.TableHeader' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template let-i="rowIndex">
        <ng-template #cacheStatusContent let-row="row">
          <span title="{{ row.requestDetails?.responseHeaders?.['x-ogo-cache'] }}">
            {{ row.requestDetails?.responseHeaders?.['x-ogo-cache'] }}
          </span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            datatableColumn;
            context: {
              row: row,
              cellSlug: FiltersEnum.CACHE_STATUS,
              cellValue: row.requestDetails?.responseHeaders?.['x-ogo-cache'],
              template: trackTemplate,
              customContent: cacheStatusContent
            }
          "
        ></ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-curPage="filters.index" let-pageSize="size">
        <small class="ogo-ngx-datatable-footer"
          >Total : {{ total | number: '' : (lang == 'fr' ? 'fr-FR' : 'en-US') }}
          {{ total > 1 ? ('reqs' | translate) : ('req' | translate) }}
        </small>
        <div class="flex-align-items ml-5">
          <small class="ogo-ngx-datatable-footer">{{ 'TotalPerPage' | translate }} :&nbsp;</small>
          <select
            class="form-control form-control-sm"
            [(ngModel)]="size"
            (change)="loadLogsPage()"
            style="min-width: 60px"
          >
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="index + 1"
          [size]="size"
          [count]="total > 10000 ? 10000 : total"
          (change)="changePage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <ng-template #loader>
    <div class="component-loader">
      <div class="loader-track">
        <div class="loader-bar"></div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template
  #datatableColumn
  let-row="row"
  let-slug="cellSlug"
  let-value="cellValue"
  let-template="trackTemplate"
  let-customContent="customContent"
>
  <div>
    <div class="whole-cell text-ellipsis flex-align-items">
      <ng-container *ngTemplateOutlet="customContent; context: { row: row }"></ng-container>
      <div class="track-container" [hidden]="slug == 'cause' && row.mainAttackLabel != undefined">
        <div class="magnificient-container">
          <span
            (click)="filter(LogFiltersType.IN, slug, value)"
            class="ogo-link gte"
            *ngIf="slug == FiltersEnum.RESPONSE_TIME_GTE || slug == FiltersEnum.CONTENT_LENGTH_GTE; else defaultZoomIn"
            >≥</span
          >
          <ng-template #defaultZoomIn>
            <i (click)="filter(LogFiltersType.IN, slug, value)" class="ogo-link icofont icofont-ui-zoom-in"></i>
          </ng-template>
          <i
            *ngIf="canFilterOut(slug)"
            (click)="filter(LogFiltersType.NOTIN, slug, value)"
            class="ogo-link icofont icofont-ui-zoom-out"
          ></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>
