import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlockedIp, FiltersEnum } from 'app/services/logs.service';
import { CountryService } from 'app/services/country.service';
import { RESPONSE_TIME } from 'app/shared/highcharts/graph/graph';
import { TrackEvent } from '../track.event';

@Component({
  selector: 'app-blocked-ips-table',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './blocked-ips-table.component.html',
  styleUrl: './blocked-ips-table.component.scss',
})
export class BlockedIPsTableComponent {
  @Input() topBlockedIps: BlockedIp[];
  @Input() print: boolean = false;

  @Output() clickCountry = new EventEmitter<TrackEvent>();
  @Output() clickIp = new EventEmitter<TrackEvent>();
  @Output() whitelistIp = new EventEmitter<WhitelistIpEvent>();

  constructor(
    public translate: TranslateService,
    public countries: CountryService,
  ) {}

  async ngOnInit() {
    await this.countries.init();
  }

  trackIp(row) {
    return this.clickIp.emit({
      [FiltersEnum.IP]: row.ip,
      graphType: RESPONSE_TIME,
      site: row.site,
    });
  }

  trackCountry(countryCode) {
    return this.clickCountry.emit({
      [FiltersEnum.COUNTRY]: countryCode,
    });
  }
}

export interface WhitelistIpEvent {
  ip: string;
  site: string;
}
