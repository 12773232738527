import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { AddSiteModule } from './add-site/add-site.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { MenuItems } from './shared/menu-items/menu-items';
import { SharedModule } from './shared/shared.module';

import { CommonModule } from '@angular/common';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import * as HighchartMap from 'highcharts/highmaps';
import HighChartsExportData from 'highcharts/modules/export-data';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsOfflineExport from 'highcharts/modules/offline-exporting';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { AuthService } from './services/auth.service';
import { AutoLogoutComponent } from './auto-logout/auto-logout.component';
import { SitesService } from './services/sites.service';
import { Graph } from './shared/highcharts/graph/graph';
import { LoaderInterceptorService } from './shared/http-loader/loader-interceptor.service';
import { ClusterTypeComponent } from './theme/admin/cluster-type/cluster-type.component';
import { ClusterComponent } from './theme/admin/cluster/cluster.component';
import { ElasticsearchComponent } from './theme/admin/elasticsearch/elasticsearch.component';
import { HoteComponent } from './theme/admin/hote/hote.component';
import { OrganizationsComponent } from './theme/admin/organizations/organizations.component';
import { UtilisateurComponent } from './theme/admin/utilisateur/utilisateur.component';
import { ApiDocComponent } from './theme/api-doc/api-doc.component';
import { DashboardComponent } from './theme/dashboard/dashboard.component';
import { DelegationComponent } from './theme/delegation/delegation.component';
import { IAParametersComponent } from './theme/ia-parameters/ia-parameters.component';
import { JournalComponent } from './theme/journal/journal.component';
import { JournalBisComponent } from './theme/journalbis/journalbis.component';
import { MaintenanceComponent } from './theme/maintenance/maintenance.component';
import { MetricsComponent } from './theme/metrics/metrics.component';
import { MyOrganizationComponent } from './theme/my-organizations/my-organization.component';
import { OrganizationUsersComponent } from './theme/my-organizations/organization-users/organization-users.component';
import { OrganizationComponent } from './theme/my-organizations/organization/organization.component';
import { PushLogComponent } from './theme/my-organizations/push-log/push-log.component';
import { ReceivedSharesComponent } from './theme/my-organizations/received-shares/received-shares.component';
import { AddEditOrganizationComponent } from './theme/add-organization/add-edit-organization.component';
import { MyNotificationsComponent } from './theme/my-profile/my-notifications/my-notifications.component';
import { MyParametersComponent } from './theme/my-profile/my-parameters/my-parameters.component';
import { MyProfileComponent } from './theme/my-profile/my-profile.component';
import { MySitesComponent } from './theme/my-sites/my-sites.component';
import { PrintComponent } from './theme/print/print.component';
import { MyLogsComponent } from './theme/my-logs/my-logs.component';
import { SentSharesComponent } from './theme/my-organizations/sent-shares/sent-shares.component';
import { WorldMapComponent } from './shared/highcharts/world-map/world-map.component';
import { IaRecommendedParametersComponent } from './theme/admin/ia-recommended-parameters/ia-recommended-parameters.component';
import { ClusterSelectorComponent } from './theme/admin/cluster-selector/cluster-selector.component';
import { BadgeOrganizationComponent } from './shared/badge-organization/badge-organization.component';
import { DateRangeSelectorDirective } from './shared/date-range-selector/date-range-selector.directive';
import { LabelDelegationRoleComponent } from './shared/label-delegation-role/label-delegation-role.component';
import { LabelShareRoleComponent } from './shared/label-share-role/label-share-role.component';
import { environment } from 'environments/environment';
import { Donut } from './shared/highcharts/donut/donut';
import { Bar } from './shared/highcharts/bar-horizontal/bar';
import { MyLogsGraphsComponent } from './theme/my-logs/graphs/my-logs-graphs.component';
import { LogsService } from 'app/services/logs.service';
import { BlockedIPsTableComponent } from './theme/dashboard/blocked-ips-table/blocked-ips-table.component';
import { SlowRequestsTableComponent } from './theme/dashboard/slow-requests-table/slow-requests-table.component';
import { MostFrequentRequestsTableComponent } from './theme/dashboard/most-frequent-requests-table/most-frequent-requests-table.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { TooltipMenuComponent } from './tooltip-menu/tooltip-menu.component';
import { MyLogsFiltersComponent } from './theme/my-logs/filters/my-logs-filters.component';
import { SiteSelectorComponent } from './shared/site-selector/site-selector.component';
import { DashboardCardComponent } from './theme/dashboard/dashboard-card/dashboard-card.component';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsNoData(Highcharts);
HighchartsExport(Highcharts);
HighchartsOfflineExport(Highcharts);
HighChartsExportData(Highcharts);
HighchartsExport(HighchartMap);
HighchartsOfflineExport(HighchartMap);
HighChartsExportData(HighchartMap);

// weird highcharts fix : https://github.com/highcharts/highcharts/issues/20572
(function (H) {
  H.wrap(H.Point.prototype, 'pos', function (proceed) {
    if (!this.destroyed) {
      return proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    }
  });
})(Highcharts);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?version=${environment.version}`); // never cached
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoaderComponent,
    AutoLogoutComponent,
    DashboardComponent,
    DashboardCardComponent,
    PrintComponent,
    MySitesComponent,
    MyLogsComponent,
    MyLogsGraphsComponent,
    MyLogsFiltersComponent,
    IAParametersComponent,
    ApiDocComponent,
    MetricsComponent,
    MyProfileComponent,
    MyOrganizationComponent,
    MyParametersComponent,
    ReceivedSharesComponent,
    SentSharesComponent,
    MyNotificationsComponent,
    PushLogComponent,
    OrganizationComponent,
    OrganizationsComponent,
    AddEditOrganizationComponent,
    UtilisateurComponent,
    ClusterComponent,
    ElasticsearchComponent,
    ClusterTypeComponent,
    IAParametersComponent,
    HoteComponent,
    OrganizationUsersComponent,
    MaintenanceComponent,
    DelegationComponent,
    JournalComponent,
    JournalBisComponent,
    WorldMapComponent,
    IaRecommendedParametersComponent,
    ClusterSelectorComponent,
    BadgeOrganizationComponent,
    LabelDelegationRoleComponent,
    LabelShareRoleComponent,
    DateRangeSelectorDirective,
    BlockedIPsTableComponent,
    SlowRequestsTableComponent,
    MostFrequentRequestsTableComponent,
    TooltipMenuComponent,
    SiteSelectorComponent,
  ],
  bootstrap: [AppComponent],
  exports: [CommonModule],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AddSiteModule,
    NgxPaginationModule,
    NgSelectModule,
    NgxDatatableModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: 'toast-bottom-right',
      maxOpened: 3,
      progressBar: true,
    }),
    HighchartsChartModule,
  ],
  providers: [
    MenuItems,
    AuthService,
    SitesService,
    LogsService,
    Graph,
    Bar,
    Donut,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }).providers!,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
