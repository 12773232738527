import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Global } from '../../global';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit {
  maxRequest = 0;
  maxDebit = 0;
  maxSite = 0;
  data: any = {};
  cluster: any = {};
  clusters: any = [];
  prometheus: any = {};
  clusterTemplate: any = {};
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data: { clusters: any }) => {
      this.clusters = data.clusters;
      this.clusterSelected(this.clusters[0]);
    });
  }

  clusterSelected(cluster: any) {
    this.prometheus = {};
    this.data = {};
    this.cluster = this.clusters.find((p: any) => p.id === Number(cluster.id)) || {};
    this.getClusterTemplate(cluster.clusterTypeId);
    this.getPrometheusData(cluster.id);
    this.getElasticSearchData(cluster.id);
  }

  getClusterTemplate(id: number) {
    this.http.post(Global.baseUrl + 'clusterType/get', {}).subscribe((res: any) => {
      if (!res.hasError) {
        this.clusterTemplate = res.items.find((cT) => cT.id == this.cluster.clusterTypeId);
        this.maxDebit = this.clusterTemplate.debitMax;
        this.maxSite = this.clusterTemplate.activeSiteMax;
        this.maxRequest = this.clusterTemplate.requestPerSecMax;
        this.clusterTemplate.debitMax2 = this.bytesToSize(this.clusterTemplate.debitMax);
      }
    });
  }

  getPrometheusData(id?: number) {
    const req = {
      data: {
        clusterId: id,
      },
    };

    this.http.post(Global.baseUrl + 'prometheus/metrics', req).subscribe((res: any) => {
      if (!res.hasError) {
        const temp = res.item.queryResult;
        for (const key in temp) {
          if (temp.hasOwnProperty(key)) {
            const value = temp[key];
            this.prometheus[key] = value?.data?.result[0]?.value[1];
          }
        }
        this.data.sitesCount = res.item.siteCount;
        const [val1, val2] = [
          Number(this.prometheus.ogo_requests_total),
          Number(this.prometheus.ogo_requests_brain_invalidated_total),
        ];
        this.data.rate = Number(Number.parseFloat((val1 + val2).toString()).toPrecision(3)) || 0;
      }
    });
  }

  getElasticSearchData(id?: number) {
    const req = {
      data: {
        clusterId: id,
      },
    };
    this.http.post(Global.baseUrl + 'es/metrics', req).subscribe((res: any) => {
      if (!res.hasError) {
        this.data.bandwidth = Math.round(res.item.Bandwidth / 1000000 / 1000)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        this.data.volumetry = (Math.trunc((res.item.Volumetry * (30 / 7)) / 100000) / 10)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
    });
  }

  bytesToSize(bytes: number) {
    bytes = bytes;
    const sizes = ['bit/s', 'Kb/s', 'Mb/s', 'Gb/s', 'Tb/s'];
    if (bytes === 0) {
      return 'n/a';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i === 0) {
      return { val: bytes, unit: sizes[i] };
    }
    return {
      val: Math.ceil(bytes / Math.pow(1024, i)),
      unit: sizes[i],
    };
  }
}
