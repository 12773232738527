<app-site-selector
  [ctx]="ctx"
  (change)="getLogs()"
  (refresh)="getLogs()"
  (onPeriodEmitted)="onPeriodEmitted($event)"
  [options]="{ showClusters: true, showTags: false, showSites: false, showDatePicker: false, canAutoRefresh: false }"
  [datePickerSlug]="'journal'"
  [isLoading]="isLoading"
>
  <div class="flex-align-items search-input">
    <label>{{ 'Type' | translate }}</label>
    <select [(ngModel)]="filters.type" (change)="refresh()" [disabled]="isLoading" class="form-control form-control-sm">
      <option value="">{{ 'All' | translate }}</option>
      <option value="SECURITY">{{ 'Security' | translate }}</option>
      <option value="MONITORING">{{ 'Monitoring' | translate }}</option>
    </select>
  </div>
  <div class="flex-align-items search-input">
    <label>{{ 'Severity' | translate }}</label>
    <select
      [(ngModel)]="filters.severity"
      (change)="refresh()"
      [disabled]="isLoading"
      class="form-control form-control-sm"
    >
      <option value="">{{ 'All' | translate }}</option>
      <option value="1">LOW</option>
      <option value="2">MEDIUM</option>
      <option value="3">HIGH</option>
    </select>
  </div>
  <div class="flex-align-items search-input">
    <label style="width: unset">{{ 'Search' | translate }}</label>
    <input
      style="width: 200px"
      #search
      [(ngModel)]="filters.search"
      [disabled]="isLoading"
      class="form-control form-control-sm"
      autocomplete="off"
      type="other"
      placeholder="Site"
    />
  </div>
  <button
    app-date-range-selector
    [disabled]="isLoading"
    [start]="filters.period.start"
    [end]="filters.period.end"
    [minDateDays]="90"
    [ranges]="['15M', '1H', '4H', 'T', 'Y', '7', '30', '90']"
    [ctx]="ctx"
    (period)="onPeriodEmitted($event)"
    [timePicker]="true"
    class="btn btn-primary"
    id="reportrange-journalbis"
  >
    <i class="icofont icofont-ui-calendar mr-2"></i>
    <span></span>
    <div class="arrow-down"></div>
  </button>
</app-site-selector>

<div class="card mt-4">
  <div class="col-md-12">
    <div class="row" style="padding-top: 15px">
      <div class="col-md-12 mt-2 flex-align-items">
        <h4 [innerHTML]="'Journal' | translate"></h4>
        <label style="position: relative; top: 1px" class="badge badge-sm bg-dark mx-2">{{ 'BETA' | translate }}</label>
        <label style="position: relative; top: 1px" class="badge badge-sm bg-dark"
          ><a
            style="color: inherit"
            target="_blank"
            [href]="lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK + '/journal' : EXTERNAL_HELP_ENGLISH_LINK + '/journal'"
            >?</a
          ></label
        >
      </div>
    </div>

    <ngx-datatable
      [messages]="{ emptyMessage: translate.instant('NoData') }"
      #logsTable
      class="data-table table-responsive mb-3"
      columnMode="force"
      [limit]="20"
      [rows]="logs"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="40"
      [rowClass]="getRowClass"
    >
      <ngx-datatable-row-detail [rowHeight]="'auto'">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="padding: 20px">
            <json-viewer [json]="row.event" [expanded]="true"></json-viewer>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column [minWidth]="90" [maxWidth]="90">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <span (click)="toggleExpandRow(row)" class="ogo-link" style="font-size: 12px">
            <a
              href="javascript:void(0)"
              class="ogo-link icon"
              [class.icon-angle-down]="expanded"
              [class.icon-angle-right]="!expanded"
              style="font-size: 10px"
              title="{{ 'Details' | translate }}"
            >
            </a>
            {{ 'Details' | translate }}
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="0">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Date' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span title="{{ row.date | dateFormat }}">{{ row.date | dateFormat }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="2">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Site'"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span title="{{ row.site }}">{{ row.site }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="3">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Event' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.subtype == 'newIpBlocked'; else genericSubtype" (click)="!row.outOfDate && trackEvent(row)">
            <a [ngClass]="{ clickable: !row.outOfDate }" [ngStyle]="{ cursor: row.outOfDate ? 'default' : 'cursor' }">
              <span class="mr-2">{{ 'event-' + row.subtype | translate }}</span>
              <div
                *ngIf="row.event.countryCode2"
                [attr.title]="countries[row.event.countryCode2]"
                class="fi fi-{{ row.event.countryCode2 | lowercase }}"
              ></div>
              <span class="ml-2">{{ row.event.ip }}</span>
            </a>
          </span>
          <ng-template #genericSubtype>
            <div class="flex-align-items">
              <span *ngIf="row.experimental" class="badge-outline mr-2" style="padding: 0 5px">{{
                'Experimental' | translate
              }}</span>
              <span (click)="!row.outOfDate && row.subtype != 'siteRateLimit' && trackEvent(row)">
                <a
                  [ngClass]="{ clickable: !row.outOfDate && row.subtype != 'siteRateLimit' }"
                  [ngStyle]="{ cursor: row.outOfDate || row.subtype == 'siteRateLimit' ? 'default' : 'cursor' }"
                >
                  {{ 'event-' + row.subtype | translate }}
                </a>
              </span>
              <label
                *ngIf="row.subtype == 'siteRateLimit'"
                class="badge badge-sm bg-dark m-0 ml-2"
                style="top: 0"
                placement="right"
                [ngbTooltip]="'SiteRateLimitTooltip' | translate"
                >?</label
              >
            </div>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="200" [maxWidth]="200"
        >>
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Type' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span title="{{ row.type | titlecase | translate }}">{{ row.type | titlecase | translate }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="200" [maxWidth]="200">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Cluster' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span title="{{ row.cluster.nom }}">{{ row.cluster.nom }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [minWidth]="90" [maxWidth]="90">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Severity' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span title="{{ severity[row.severity] || '-' }}" class="severity-{{ severity[row.severity] }}">{{
            severity[row.severity] || '-'
          }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-curPage="filters.index" let-pageSize="size">
          <small class="ogo-ngx-datatable-footer"
            >Total : {{ total | number: '' : (lang == 'fr' ? 'fr-FR' : 'en-US') }} {{ 'entrie' | translate
            }}{{ total > 1 ? 's' : '' }}
          </small>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="index + 1"
            [size]="size"
            [count]="total"
            (change)="changePage($event)"
          >
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>
